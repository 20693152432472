<template>
  <v-app>
    <!-- <pages-core-app-bar /> -->

    <pages-core-view />

    <!-- <pages-core-footer /> -->
    <!-- <v-snackbar v-model="snackbar" class="mt-8" :color="snackbarColor" multi-line :timeout="snackbarTimeout" top right>
      {{ snackbarText }}
      <v-btn class="pr-0" color="white" text @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <base-material-snackbar
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      {{ snackbarText }}
    </base-material-snackbar>
  </v-app>
</template>

<script>
import { EventBus } from "../../EventBus"
export default {
  name: "PagesIndex",
  components: {
    // PagesCoreAppBar: () => import("./components/core/AppBar"),
    // PagesCoreFooter: () => import("./components/core/Footer"),
    PagesCoreView: () => import("./components/core/View")
  },
  beforeRouteEnter(to, from, next) {
    //console.log("beforeRouteEnter index pages ", localStorage.accountToken)
    if (localStorage.accountToken || sessionStorage.accountToken) {
      //console.log("beforeRouteEnter index pages next/ ", to)
      next("/")
    } else {
      //console.log("beforeRouteEnter index pages next")
      next()
    }
  },
  data: () => ({
    snackbarText: "",
    snackbarColor: "error",
    snackbarTimeout: 5000,
    login: false,
    snackbar: false
  }),
  computed: {
    accountToken() {
      return this.$store.getters.accountToken
    }
  },
  watch: {
    accountToken(val) {
      this.$log("accountToken index pages ", val)
      if (!val) {
        //this.$log("userKey change ", this.$router)
        if (this.$router.history.current.path != "/autentificare") {
          this.$router.push("/autentificare")
        }
      } else {
        // if (String(this.$router.history.current.path).indexOf("/pages") > -1) {
        this.$router.push("/").catch(() => {
          //this.$log(error)
        })
        // }
      }
    }
  },
  created() {
    //this.$log("created accountToken", this.$store.getters.darkMode)
    //this.$log("created accountToken", this.$router.path)
    this.$i18n.locale = this.$store.getters.language
    this.$vuetify.theme.dark = this.$store.getters.darkMode
    //this.$log("settingssssssssssssss2 ", this.$store.getters.primaryColor)
    this.$vuetify.theme.themes.dark.primary = this.$store.getters.primaryColorDark
    this.$vuetify.theme.themes.light.primary = this.$store.getters.primaryColor
    EventBus.$on("show-alert", salert => {
      //this.$log("alertttttttttttttttttttttt ind log", salert)
      this.snackbarColor = salert.color ? salert.color : "#2196F3"
      this.snackbarTimeout = salert.timeOut ? salert.timeOut : 5000
      this.snackbarText = salert.message
      this.snackbar = true
    })
  },

  beforeDestroy() {
    //this.$vuetify.theme.dark = false
  }
}
</script>
